/* import the fonts */
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

/* preapre the fonts */
@font-face {
    font-family: "ubuntu";
    src: url("./../fonts/ubuntu-regular.ttf");
}

/* the general styles */
* {
    box-sizing: border-box;
}

body, input, button, textarea, select {
    margin: 0;
    padding:0;
    font-family: "Rubik", sans-serif;
}

strong {
    font-weight: 900;
    color: #000;
}

/* the logo styles */
.logo {
    margin: 10px;
}

/* the blog styles */
#blog div {
    line-height: 34px;
}
#blog p {
    line-height: 34px;
}
#blog ul {
    list-style-type: disc;
    margin: 4px 20px;
}
#blog ol {
    list-style-type: decimal;
    margin: 4px 20px;
}
#blog a {
    color: #b66dff;
}
#blog h1 {
    font-size: 40px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 2px;
}
#blog h2 {
    font-size: 30px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 2px;
}

/* import the tailwind css library */
@tailwind base;
@tailwind components;
@tailwind utilities;