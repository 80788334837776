/* the sidebar styles */
@media screen and (max-width:800px){
    .sidebar{
        z-index: 10000000;
    }
}

.sidebar {
    box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item, .navbar {
    z-index: 10000;
}

/* the scroll styles */
.e-dlg-center-center, .e-quick-popup-wrapper.e-device {
    z-index: 1000000 !important;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 40px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}